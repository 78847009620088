import { default as aboutu8Sb32ihwPMeta } from "/app/pages/about.vue?macro=true";
import { default as testqd8hShr2IcMeta } from "/app/pages/blog/[id]/test.vue?macro=true";
import { default as _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta } from "/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue?macro=true";
import { default as _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta } from "/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue?macro=true";
import { default as _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta } from "/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue?macro=true";
import { default as _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta } from "/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue?macro=true";
import { default as _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta } from "/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue?macro=true";
import { default as _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta } from "/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue?macro=true";
import { default as A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta } from "/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue?macro=true";
import { default as Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta } from "/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue?macro=true";
import { default as Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta } from "/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue?macro=true";
import { default as Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta } from "/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue?macro=true";
import { default as DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta } from "/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue?macro=true";
import { default as DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta } from "/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue?macro=true";
import { default as DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta } from "/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue?macro=true";
import { default as DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta } from "/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue?macro=true";
import { default as DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta } from "/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue?macro=true";
import { default as DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta } from "/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue?macro=true";
import { default as DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta } from "/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue?macro=true";
import { default as DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta } from "/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue?macro=true";
import { default as DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta } from "/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue?macro=true";
import { default as DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta } from "/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue?macro=true";
import { default as DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta } from "/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue?macro=true";
import { default as Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta } from "/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue?macro=true";
import { default as Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta } from "/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue?macro=true";
import { default as Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta } from "/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue?macro=true";
import { default as indexgWaMlevJ7RMeta } from "/app/pages/blog/index.vue?macro=true";
import { default as Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta } from "/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue?macro=true";
import { default as Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta } from "/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue?macro=true";
import { default as Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta } from "/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue?macro=true";
import { default as Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta } from "/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue?macro=true";
import { default as Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta } from "/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue?macro=true";
import { default as Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta } from "/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue?macro=true";
import { default as Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta } from "/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue?macro=true";
import { default as This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta } from "/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue?macro=true";
import { default as Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta } from "/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue?macro=true";
import { default as Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta } from "/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue?macro=true";
import { default as cityscapeB4Fv8lIb1eMeta } from "/app/pages/cityscape.vue?macro=true";
import { default as compilation0yjGRVmHiYMeta } from "/app/pages/compilation.vue?macro=true";
import { default as cookie_45policy2bZRk7g5I8Meta } from "/app/pages/cookie-policy.vue?macro=true";
import { default as indexZQOhDL27HoMeta } from "/app/pages/design-studio/index.vue?macro=true";
import { default as designersQNW9hu68acMeta } from "/app/pages/dubai/designers.vue?macro=true";
import { default as realtybrbbQJQAcUMeta } from "/app/pages/dubai/realty.vue?macro=true";
import { default as dubai2W7R6ZH480DMeta } from "/app/pages/dubai2.vue?macro=true";
import { default as for_45designersL9HRWdjc5oMeta } from "/app/pages/for-designers.vue?macro=true";
import { default as for_45developersM85EQO49RgMeta } from "/app/pages/for-developers.vue?macro=true";
import { default as for_45finishersOwdClBzx5IMeta } from "/app/pages/for-finishers.vue?macro=true";
import { default as for_45furniture_45makersUihflGw9RnMeta } from "/app/pages/for-furniture-makers.vue?macro=true";
import { default as for_45householdsNX1Wvwxt6cMeta } from "/app/pages/for-households.vue?macro=true";
import { default as galleryvsTGmCPX6gMeta } from "/app/pages/gallery.vue?macro=true";
import { default as getsharedJH75BX2jiuMeta } from "/app/pages/getshared.vue?macro=true";
import { default as gitexMoroccocjBvrSaYIUMeta } from "/app/pages/gitexMorocco.vue?macro=true";
import { default as indexamjyqCQoXrMeta } from "/app/pages/help-center/about-dream-flat/index.vue?macro=true";
import { default as indexeHVpFSjFO4Meta } from "/app/pages/help-center/create-content-and-project-demonstration/index.vue?macro=true";
import { default as indexMOeWwviCx6Meta } from "/app/pages/help-center/creating-project/index.vue?macro=true";
import { default as indexNgHTocZnJHMeta } from "/app/pages/help-center/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as launcherlogin622USJAlVYMeta } from "/app/pages/launcherlogin.vue?macro=true";
import { default as license1oPxwXaJfhMeta } from "/app/pages/license.vue?macro=true";
import { default as new8XRwwjkl9dMeta } from "/app/pages/new.vue?macro=true";
import { default as indexTtCmtGQ9NRMeta } from "/app/pages/offer/index.vue?macro=true";
import { default as indextoyI8HEumHMeta } from "/app/pages/offer/pay-status/index.vue?macro=true";
import { default as _91id_93yuH5OfQadmMeta } from "/app/pages/offer/pay/[id].vue?macro=true";
import { default as indexpIDCdxr2vsMeta } from "/app/pages/offer/plans/index.vue?macro=true";
import { default as offer2TwTpaUgg1SMeta } from "/app/pages/offer2.vue?macro=true";
import { default as pay_45status0cljlImIloMeta } from "/app/pages/pay-status.vue?macro=true";
import { default as _91id_93Z8sm1odpbeMeta } from "/app/pages/pay/[id].vue?macro=true";
import { default as plansTUY73vr0OjMeta } from "/app/pages/plans.vue?macro=true";
import { default as privacy_45policyOPxqa3IsSYMeta } from "/app/pages/privacy-policy.vue?macro=true";
import { default as verify_passwordgLQvYSM7fBMeta } from "/app/pages/profile/verify_password.vue?macro=true";
import { default as verify_signupoFYdrbpbWiMeta } from "/app/pages/profile/verify_signup.vue?macro=true";
import { default as profileupBlgdy3vLMeta } from "/app/pages/profile.vue?macro=true";
import { default as real_45estateRBx1btoobbMeta } from "/app/pages/real-estate.vue?macro=true";
import { default as terms_45of_45useA3Owpnmw23Meta } from "/app/pages/terms-of-use.vue?macro=true";
import { default as thank_you_designerOlLS7dGGyrMeta } from "/app/pages/thank_you_designer.vue?macro=true";
import { default as thank_you_developerr71Kq0pOduMeta } from "/app/pages/thank_you_developer.vue?macro=true";
import { default as _91id_93vBwXVvXpAaMeta } from "/app/pages/verify/[id].vue?macro=true";
export default [
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about",
    path: aboutu8Sb32ihwPMeta?.path ?? "/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test",
    path: testqd8hShr2IcMeta?.path ?? "/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog",
    path: indexgWaMlevJ7RMeta?.path ?? "/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation",
    path: compilation0yjGRVmHiYMeta?.path ?? "/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio",
    path: indexZQOhDL27HoMeta?.path ?? "/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers",
    path: designersQNW9hu68acMeta?.path ?? "/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty",
    path: realtybrbbQJQAcUMeta?.path ?? "/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2",
    path: dubai2W7R6ZH480DMeta?.path ?? "/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers",
    path: for_45developersM85EQO49RgMeta?.path ?? "/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery",
    path: galleryvsTGmCPX6gMeta?.path ?? "/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared",
    path: getsharedJH75BX2jiuMeta?.path ?? "/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat",
    path: indexamjyqCQoXrMeta?.path ?? "/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration",
    path: indexeHVpFSjFO4Meta?.path ?? "/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project",
    path: indexMOeWwviCx6Meta?.path ?? "/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center",
    path: indexNgHTocZnJHMeta?.path ?? "/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin",
    path: launcherlogin622USJAlVYMeta?.path ?? "/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license",
    path: license1oPxwXaJfhMeta?.path ?? "/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new",
    path: new8XRwwjkl9dMeta?.path ?? "/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer",
    path: indexTtCmtGQ9NRMeta?.path ?? "/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status",
    path: indextoyI8HEumHMeta?.path ?? "/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans",
    path: indexpIDCdxr2vsMeta?.path ?? "/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2",
    path: offer2TwTpaUgg1SMeta?.path ?? "/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status",
    path: pay_45status0cljlImIloMeta?.path ?? "/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans",
    path: plansTUY73vr0OjMeta?.path ?? "/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile",
    path: profileupBlgdy3vLMeta?.path ?? "/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate",
    path: real_45estateRBx1btoobbMeta?.path ?? "/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___en",
    path: aboutu8Sb32ihwPMeta?.path ?? "/en/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___es",
    path: aboutu8Sb32ihwPMeta?.path ?? "/es/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___ru",
    path: aboutu8Sb32ihwPMeta?.path ?? "/ru/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___fr",
    path: aboutu8Sb32ihwPMeta?.path ?? "/fr/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aboutu8Sb32ihwPMeta?.name ?? "about___ar",
    path: aboutu8Sb32ihwPMeta?.path ?? "/ar/about",
    meta: aboutu8Sb32ihwPMeta || {},
    alias: aboutu8Sb32ihwPMeta?.alias || [],
    redirect: aboutu8Sb32ihwPMeta?.redirect,
    component: () => import("/app/pages/about.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test___en",
    path: testqd8hShr2IcMeta?.path ?? "/en/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test___es",
    path: testqd8hShr2IcMeta?.path ?? "/es/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test___ru",
    path: testqd8hShr2IcMeta?.path ?? "/ru/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test___fr",
    path: testqd8hShr2IcMeta?.path ?? "/fr/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: testqd8hShr2IcMeta?.name ?? "blog-id-test___ar",
    path: testqd8hShr2IcMeta?.path ?? "/ar/blog/:id()/test",
    meta: testqd8hShr2IcMeta || {},
    alias: testqd8hShr2IcMeta?.alias || [],
    redirect: testqd8hShr2IcMeta?.redirect,
    component: () => import("/app/pages/blog/[id]/test.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat___en",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/en/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat___es",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/es/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat___ru",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/ru/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat___fr",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/fr/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.name ?? "blog-10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat___ar",
    path: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.path ?? "/ar/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat",
    meta: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta || {},
    alias: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.alias || [],
    redirect: _10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlatYKyxqpLrNVMeta?.redirect,
    component: () => import("/app/pages/blog/10_Bedroom_Color_Ideas_to_Enhance_Your_Dream_Space_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat___en",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/en/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat___es",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/es/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat___ru",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/ru/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat___fr",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/fr/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.name ?? "blog-10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat___ar",
    path: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.path ?? "/ar/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat",
    meta: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta || {},
    alias: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.alias || [],
    redirect: _10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlatpSYn6vrVtmMeta?.redirect,
    component: () => import("/app/pages/blog/10_Kitchen_Layout_Ideas_Unleash_Your_Culinary_Creativity_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat___en",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/en/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat___es",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/es/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat___ru",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/ru/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat___fr",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/fr/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.name ?? "blog-10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat___ar",
    path: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.path ?? "/ar/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat",
    meta: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta || {},
    alias: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.alias || [],
    redirect: _10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlatHjdIN7dN2IMeta?.redirect,
    component: () => import("/app/pages/blog/10_Luxury_Modern_Kitchen_Design_Ideas_Brought_to_Life_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience___en",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/en/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience___es",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/es/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience___ru",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/ru/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience___fr",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/fr/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.name ?? "blog-10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience___ar",
    path: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.path ?? "/ar/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience",
    meta: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta || {},
    alias: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.alias || [],
    redirect: _10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience7QRjixAyawMeta?.redirect,
    component: () => import("/app/pages/blog/10_Spa_tastic_Modern_Bathroom_Floor_Tile_Ideas_to_Elevate_Your_Home_Spa_Experience.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App___en",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/en/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App___es",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/es/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App___ru",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/ru/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App___fr",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/fr/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.name ?? "blog-3D_Interior_Design_Dive_Into_New_World_with_This_App___ar",
    path: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.path ?? "/ar/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App",
    meta: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta || {},
    alias: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.alias || [],
    redirect: _3D_Interior_Design_Dive_Into_New_World_with_This_AppBEhjuV2eOSMeta?.redirect,
    component: () => import("/app/pages/blog/3D_Interior_Design_Dive_Into_New_World_with_This_App.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors___en",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/en/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors___es",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/es/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors___ru",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/ru/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors___fr",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/fr/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.name ?? "blog-5_Best_Ideas_of_House_Exteriors___ar",
    path: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.path ?? "/ar/blog/5_Best_Ideas_of_House_Exteriors",
    meta: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta || {},
    alias: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.alias || [],
    redirect: _5_Best_Ideas_of_House_ExteriorsbyRABTZzipMeta?.redirect,
    component: () => import("/app/pages/blog/5_Best_Ideas_of_House_Exteriors.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark___en",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/en/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark___es",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/es/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark___ru",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/ru/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark___fr",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/fr/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.name ?? "blog-A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark___ar",
    path: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.path ?? "/ar/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark",
    meta: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta || {},
    alias: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.alias || [],
    redirect: A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_SparkcexxnhKSiBMeta?.redirect,
    component: () => import("/app/pages/blog/A_Comprehensive_Interior_Design_App_Suite_Ignite_Your_Design_Spark.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application___en",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/en/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application___es",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/es/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application___ru",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/ru/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application___fr",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/fr/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.name ?? "blog-Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application___ar",
    path: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.path ?? "/ar/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application",
    meta: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta || {},
    alias: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.alias || [],
    redirect: Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_ApplicationLrlXLrKVviMeta?.redirect,
    component: () => import("/app/pages/blog/Contemporary_Design_Trends_Implement_Any_Ideas_with_an_Innovative_Application.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat___en",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/en/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat___es",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/es/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat___ru",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/ru/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat___fr",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/fr/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.name ?? "blog-Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat___ar",
    path: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.path ?? "/ar/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat",
    meta: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta || {},
    alias: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.alias || [],
    redirect: Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlatCVSlG86ySkMeta?.redirect,
    component: () => import("/app/pages/blog/Create_Your_Ultimate_Relaxation_Retreat_Realizing_Master_Bathroom_Ideas_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design___en",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/en/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design___es",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/es/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design___ru",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/ru/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design___fr",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/fr/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.name ?? "blog-Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design___ar",
    path: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.path ?? "/ar/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design",
    meta: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta || {},
    alias: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.alias || [],
    redirect: Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_DesignSblFhXStgdMeta?.redirect,
    component: () => import("/app/pages/blog/Design_Your_Room_Easily_Even_If_You_re_New_to_Interior_Design.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software___en",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/en/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software___es",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/es/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software___ru",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/ru/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software___fr",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/fr/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.name ?? "blog-DreamFlat_A_Modern_Room_Design_Software___ar",
    path: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.path ?? "/ar/blog/DreamFlat_A_Modern_Room_Design_Software",
    meta: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta || {},
    alias: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.alias || [],
    redirect: DreamFlat_A_Modern_Room_Design_SoftwarePskQm3JedLMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Modern_Room_Design_Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner___en",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/en/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner___es",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/es/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner___ru",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/ru/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner___fr",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/fr/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.name ?? "blog-DreamFlat_A_Perfect_Room_Planner___ar",
    path: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.path ?? "/ar/blog/DreamFlat_A_Perfect_Room_Planner",
    meta: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta || {},
    alias: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.alias || [],
    redirect: DreamFlat_A_Perfect_Room_PlannerpdZ2SEU11HMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_A_Perfect_Room_Planner.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail___en",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/en/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail___es",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/es/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail___ru",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/ru/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail___fr",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/fr/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.name ?? "blog-DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail___ar",
    path: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.path ?? "/ar/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail",
    meta: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta || {},
    alias: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.alias || [],
    redirect: DreamFlat_Design_Kitchen_in_Pixel_45Perfect_DetailqsGsh0s5oFMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Design_Kitchen_in_Pixel-Perfect_Detail.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning___en",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/en/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning___es",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/es/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning___ru",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/ru/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning___fr",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/fr/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.name ?? "blog-DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning___ar",
    path: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.path ?? "/ar/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning",
    meta: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta || {},
    alias: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.alias || [],
    redirect: DreamFlat_Revolutionizing_Interior_Design_with_Cutting_45Edge_3D_Room_PlanningTHJwLjTEQdMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Revolutionizing_Interior_Design_with_Cutting-Edge_3D_Room_Planning.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic___en",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/en/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic___es",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/es/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic___ru",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/ru/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic___fr",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/fr/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.name ?? "blog-DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic___ar",
    path: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.path ?? "/ar/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic",
    meta: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta || {},
    alias: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.alias || [],
    redirect: DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_MagicpisrqXZfP4Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Unleashing_Your_Inner_Design_Guru_with_Free_Furniture_Arrangement_Magic.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight___en",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/en/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight___es",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/es/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight___ru",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/ru/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight___fr",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/fr/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.name ?? "blog-DreamFlat_Where_Your_Dream_Home_Takes_Flight___ar",
    path: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.path ?? "/ar/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight",
    meta: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta || {},
    alias: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.alias || [],
    redirect: DreamFlat_Where_Your_Dream_Home_Takes_FlightXgVKggdS1eMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Where_Your_Dream_Home_Takes_Flight.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker___en",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/en/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker___es",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/es/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker___ru",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/ru/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker___fr",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/fr/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.name ?? "blog-DreamFlat_Your_Handy_Room_Blueprint_Maker___ar",
    path: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.path ?? "/ar/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker",
    meta: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta || {},
    alias: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.alias || [],
    redirect: DreamFlat_Your_Handy_Room_Blueprint_Makerxd6ngvi2GEMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat_Your_Handy_Room_Blueprint_Maker.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool___en",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/en/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool___es",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/es/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool___ru",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/ru/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool___fr",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/fr/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.name ?? "blog-DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool___ar",
    path: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.path ?? "/ar/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool",
    meta: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta || {},
    alias: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.alias || [],
    redirect: DreamFlat_45A_45Free_45and_45Comprehensive_45Interior_45Design_45ToolZFIfRp3p3XMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-A-Free-and-Comprehensive-Interior-Design-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market___en",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/en/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market___es",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/es/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market___ru",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/ru/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market___fr",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/fr/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.name ?? "blog-DreamFlat-a-New-Player-at-Home-Design-Software-Market___ar",
    path: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.path ?? "/ar/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market",
    meta: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta || {},
    alias: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.alias || [],
    redirect: DreamFlat_45a_45New_45Player_45at_45Home_45Design_45Software_45MarketbUz99xZTT5Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-a-New-Player-at-Home-Design-Software-Market.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool___en",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/en/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool___es",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/es/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool___ru",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/ru/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool___fr",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/fr/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.name ?? "blog-DreamFlat-Home-Design-Software-A-Revolutionizing-Tool___ar",
    path: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.path ?? "/ar/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool",
    meta: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta || {},
    alias: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.alias || [],
    redirect: DreamFlat_45Home_45Design_45Software_45A_45Revolutionizing_45ToolzpxVvDXGi2Meta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Home-Design-Software-A-Revolutionizing-Tool.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software___en",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/en/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software___es",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/es/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software___ru",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/ru/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software___fr",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/fr/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.name ?? "blog-DreamFlat-Innovative-Home-Design-Software___ar",
    path: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.path ?? "/ar/blog/DreamFlat-Innovative-Home-Design-Software",
    meta: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta || {},
    alias: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.alias || [],
    redirect: DreamFlat_45Innovative_45Home_45Design_45Software4ZAI4UyF1kMeta?.redirect,
    component: () => import("/app/pages/blog/DreamFlat-Innovative-Home-Design-Software.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC___en",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/en/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC___es",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/es/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC___ru",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/ru/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC___fr",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/fr/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.name ?? "blog-Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC___ar",
    path: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.path ?? "/ar/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC",
    meta: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta || {},
    alias: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.alias || [],
    redirect: Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PCeasDFcgDs2Meta?.redirect,
    component: () => import("/app/pages/blog/Enhancing_Your_Apartment_with_Contemporary_Design_Solutions_for_Windows_PC.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream___en",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/en/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream___es",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/es/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream___ru",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/ru/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream___fr",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/fr/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.name ?? "blog-Home_Design_App_Guide_Step_Into_Your_Dream___ar",
    path: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.path ?? "/ar/blog/Home_Design_App_Guide_Step_Into_Your_Dream",
    meta: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta || {},
    alias: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.alias || [],
    redirect: Home_Design_App_Guide_Step_Into_Your_Dreamrwap7lMzcCMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Design_App_Guide_Step_Into_Your_Dream.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions___en",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/en/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions___es",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/es/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions___ru",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/ru/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions___fr",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/fr/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.name ?? "blog-Home_Interiors_A_Fresh_Perspective_on_Design_Solutions___ar",
    path: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.path ?? "/ar/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions",
    meta: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta || {},
    alias: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.alias || [],
    redirect: Home_Interiors_A_Fresh_Perspective_on_Design_SolutionsVqaHocZZjAMeta?.redirect,
    component: () => import("/app/pages/blog/Home_Interiors_A_Fresh_Perspective_on_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___en",
    path: indexgWaMlevJ7RMeta?.path ?? "/en/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___es",
    path: indexgWaMlevJ7RMeta?.path ?? "/es/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___ru",
    path: indexgWaMlevJ7RMeta?.path ?? "/ru/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___fr",
    path: indexgWaMlevJ7RMeta?.path ?? "/fr/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexgWaMlevJ7RMeta?.name ?? "blog___ar",
    path: indexgWaMlevJ7RMeta?.path ?? "/ar/blog",
    meta: indexgWaMlevJ7RMeta || {},
    alias: indexgWaMlevJ7RMeta?.alias || [],
    redirect: indexgWaMlevJ7RMeta?.redirect,
    component: () => import("/app/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions___en",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/en/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions___es",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/es/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions___ru",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/ru/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions___fr",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/fr/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.name ?? "blog-Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions___ar",
    path: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.path ?? "/ar/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions",
    meta: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta || {},
    alias: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.alias || [],
    redirect: Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_SolutionsjWyvvnVLxYMeta?.redirect,
    component: () => import("/app/pages/blog/Introducing_an_Exceptional_Home_Design_Software_Explore_the_Latest_Innovations_in_Room_Design_Solutions.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution___en",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/en/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution___es",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/es/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution___ru",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/ru/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution___fr",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/fr/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.name ?? "blog-Quest_for_the_Perfect_Home_Design_Solution___ar",
    path: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.path ?? "/ar/blog/Quest_for_the_Perfect_Home_Design_Solution",
    meta: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta || {},
    alias: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.alias || [],
    redirect: Quest_for_the_Perfect_Home_Design_SolutionAvziLLojISMeta?.redirect,
    component: () => import("/app/pages/blog/Quest_for_the_Perfect_Home_Design_Solution.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning___en",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/en/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning___es",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/es/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning___ru",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/ru/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning___fr",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/fr/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.name ?? "blog-Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning___ar",
    path: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.path ?? "/ar/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning",
    meta: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta || {},
    alias: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.alias || [],
    redirect: Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning73SxuTktlgMeta?.redirect,
    component: () => import("/app/pages/blog/Revolutionizing_Spatial_Design_with_DreamFlat_A_Seamless_Approach_to_Floor_Planning.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat___en",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/en/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat___es",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/es/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat___ru",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/ru/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat___fr",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/fr/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.name ?? "blog-Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat___ar",
    path: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.path ?? "/ar/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat",
    meta: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta || {},
    alias: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.alias || [],
    redirect: Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlatXQLAuYY6JpMeta?.redirect,
    component: () => import("/app/pages/blog/Small_Kitchen_Ideas_Smart_Solutions_for_Space_and_Style_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat___en",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/en/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat___es",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/es/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat___ru",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/ru/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat___fr",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/fr/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.name ?? "blog-Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat___ar",
    path: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.path ?? "/ar/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat",
    meta: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta || {},
    alias: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.alias || [],
    redirect: Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlatc4SwOblsTfMeta?.redirect,
    component: () => import("/app/pages/blog/Spruce_Up_Your_Space_Top_Office_Decor_Ideas_Brought_to_Life_by_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat___en",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/en/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat___es",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/es/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat___ru",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/ru/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat___fr",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/fr/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.name ?? "blog-Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat___ar",
    path: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.path ?? "/ar/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat",
    meta: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta || {},
    alias: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.alias || [],
    redirect: Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlatwHNmr2TwcNMeta?.redirect,
    component: () => import("/app/pages/blog/Step_into_the_Future_5_Modern_Kitchen_Floor_Tile_Ideas_for_2024_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools___en",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/en/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools___es",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/es/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools___ru",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/ru/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools___fr",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/fr/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.name ?? "blog-Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools___ar",
    path: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.path ?? "/ar/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools",
    meta: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta || {},
    alias: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.alias || [],
    redirect: Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_ToolsqO1EgM5wiUMeta?.redirect,
    component: () => import("/app/pages/blog/Tap_into_Your_Inner_Artist_Crafting_Your_Bathroom_Design_Using_State_of_the_Art_Tools.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration___en",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/en/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration___es",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/es/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration___ru",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/ru/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration___fr",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/fr/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.name ?? "blog-This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration___ar",
    path: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.path ?? "/ar/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration",
    meta: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta || {},
    alias: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.alias || [],
    redirect: This_Home_Design_App_is_Key_To_Your_Studio_Apartment_InspirationKa3tM9lSMIMeta?.redirect,
    component: () => import("/app/pages/blog/This_Home_Design_App_is_Key_To_Your_Studio_Apartment_Inspiration.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat___en",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/en/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat___es",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/es/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat___ru",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/ru/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat___fr",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/fr/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.name ?? "blog-Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat___ar",
    path: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.path ?? "/ar/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat",
    meta: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta || {},
    alias: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.alias || [],
    redirect: Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlatDDjSqYN8phMeta?.redirect,
    component: () => import("/app/pages/blog/Tiny_Space_Big_Dreams_Unlocking_Studio_Apartment_Ideas_Potential_with_DreamFlat.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform___en",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/en/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform___es",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/es/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform___ru",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/ru/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform___fr",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/fr/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.name ?? "blog-Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform___ar",
    path: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.path ?? "/ar/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform",
    meta: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta || {},
    alias: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.alias || [],
    redirect: Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_PlatformlR2sAHuamMMeta?.redirect,
    component: () => import("/app/pages/blog/Unlocking_New_Horizons_for_Creative_Industries_with_DreamFlats_Innovative_3D_Room_Design_Platform.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___en",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/en/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___es",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/es/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___ru",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/ru/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___fr",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/fr/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: cityscapeB4Fv8lIb1eMeta?.name ?? "cityscape___ar",
    path: cityscapeB4Fv8lIb1eMeta?.path ?? "/ar/cityscape",
    meta: cityscapeB4Fv8lIb1eMeta || {},
    alias: cityscapeB4Fv8lIb1eMeta?.alias || [],
    redirect: cityscapeB4Fv8lIb1eMeta?.redirect,
    component: () => import("/app/pages/cityscape.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___en",
    path: compilation0yjGRVmHiYMeta?.path ?? "/en/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___es",
    path: compilation0yjGRVmHiYMeta?.path ?? "/es/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___ru",
    path: compilation0yjGRVmHiYMeta?.path ?? "/ru/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___fr",
    path: compilation0yjGRVmHiYMeta?.path ?? "/fr/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: compilation0yjGRVmHiYMeta?.name ?? "compilation___ar",
    path: compilation0yjGRVmHiYMeta?.path ?? "/ar/compilation",
    meta: compilation0yjGRVmHiYMeta || {},
    alias: compilation0yjGRVmHiYMeta?.alias || [],
    redirect: compilation0yjGRVmHiYMeta?.redirect,
    component: () => import("/app/pages/compilation.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___en",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/en/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___es",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/es/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___ru",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/ru/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___fr",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/fr/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: cookie_45policy2bZRk7g5I8Meta?.name ?? "cookie-policy___ar",
    path: cookie_45policy2bZRk7g5I8Meta?.path ?? "/ar/cookie-policy",
    meta: cookie_45policy2bZRk7g5I8Meta || {},
    alias: cookie_45policy2bZRk7g5I8Meta?.alias || [],
    redirect: cookie_45policy2bZRk7g5I8Meta?.redirect,
    component: () => import("/app/pages/cookie-policy.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___en",
    path: indexZQOhDL27HoMeta?.path ?? "/en/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___es",
    path: indexZQOhDL27HoMeta?.path ?? "/es/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___ru",
    path: indexZQOhDL27HoMeta?.path ?? "/ru/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___fr",
    path: indexZQOhDL27HoMeta?.path ?? "/fr/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: indexZQOhDL27HoMeta?.name ?? "design-studio___ar",
    path: indexZQOhDL27HoMeta?.path ?? "/ar/design-studio",
    meta: indexZQOhDL27HoMeta || {},
    alias: indexZQOhDL27HoMeta?.alias || [],
    redirect: indexZQOhDL27HoMeta?.redirect,
    component: () => import("/app/pages/design-studio/index.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___en",
    path: designersQNW9hu68acMeta?.path ?? "/en/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___es",
    path: designersQNW9hu68acMeta?.path ?? "/es/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___ru",
    path: designersQNW9hu68acMeta?.path ?? "/ru/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___fr",
    path: designersQNW9hu68acMeta?.path ?? "/fr/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: designersQNW9hu68acMeta?.name ?? "dubai-designers___ar",
    path: designersQNW9hu68acMeta?.path ?? "/ar/dubai/designers",
    meta: designersQNW9hu68acMeta || {},
    alias: designersQNW9hu68acMeta?.alias || [],
    redirect: designersQNW9hu68acMeta?.redirect,
    component: () => import("/app/pages/dubai/designers.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___en",
    path: realtybrbbQJQAcUMeta?.path ?? "/en/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___es",
    path: realtybrbbQJQAcUMeta?.path ?? "/es/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___ru",
    path: realtybrbbQJQAcUMeta?.path ?? "/ru/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___fr",
    path: realtybrbbQJQAcUMeta?.path ?? "/fr/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: realtybrbbQJQAcUMeta?.name ?? "dubai-realty___ar",
    path: realtybrbbQJQAcUMeta?.path ?? "/ar/dubai/realty",
    meta: realtybrbbQJQAcUMeta || {},
    alias: realtybrbbQJQAcUMeta?.alias || [],
    redirect: realtybrbbQJQAcUMeta?.redirect,
    component: () => import("/app/pages/dubai/realty.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___en",
    path: dubai2W7R6ZH480DMeta?.path ?? "/en/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___es",
    path: dubai2W7R6ZH480DMeta?.path ?? "/es/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___ru",
    path: dubai2W7R6ZH480DMeta?.path ?? "/ru/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___fr",
    path: dubai2W7R6ZH480DMeta?.path ?? "/fr/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: dubai2W7R6ZH480DMeta?.name ?? "dubai2___ar",
    path: dubai2W7R6ZH480DMeta?.path ?? "/ar/dubai2",
    meta: dubai2W7R6ZH480DMeta || {},
    alias: dubai2W7R6ZH480DMeta?.alias || [],
    redirect: dubai2W7R6ZH480DMeta?.redirect,
    component: () => import("/app/pages/dubai2.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___en",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/en/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___es",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/es/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___ru",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/ru/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___fr",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/fr/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45designersL9HRWdjc5oMeta?.name ?? "for-designers___ar",
    path: for_45designersL9HRWdjc5oMeta?.path ?? "/ar/for-designers",
    meta: for_45designersL9HRWdjc5oMeta || {},
    alias: for_45designersL9HRWdjc5oMeta?.alias || [],
    redirect: for_45designersL9HRWdjc5oMeta?.redirect,
    component: () => import("/app/pages/for-designers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___en",
    path: for_45developersM85EQO49RgMeta?.path ?? "/en/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___es",
    path: for_45developersM85EQO49RgMeta?.path ?? "/es/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___ru",
    path: for_45developersM85EQO49RgMeta?.path ?? "/ru/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___fr",
    path: for_45developersM85EQO49RgMeta?.path ?? "/fr/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45developersM85EQO49RgMeta?.name ?? "for-developers___ar",
    path: for_45developersM85EQO49RgMeta?.path ?? "/ar/for-developers",
    meta: for_45developersM85EQO49RgMeta || {},
    alias: for_45developersM85EQO49RgMeta?.alias || [],
    redirect: for_45developersM85EQO49RgMeta?.redirect,
    component: () => import("/app/pages/for-developers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___en",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/en/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___es",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/es/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___ru",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/ru/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___fr",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/fr/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45finishersOwdClBzx5IMeta?.name ?? "for-finishers___ar",
    path: for_45finishersOwdClBzx5IMeta?.path ?? "/ar/for-finishers",
    meta: for_45finishersOwdClBzx5IMeta || {},
    alias: for_45finishersOwdClBzx5IMeta?.alias || [],
    redirect: for_45finishersOwdClBzx5IMeta?.redirect,
    component: () => import("/app/pages/for-finishers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___en",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/en/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___es",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/es/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___ru",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/ru/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___fr",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/fr/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45furniture_45makersUihflGw9RnMeta?.name ?? "for-furniture-makers___ar",
    path: for_45furniture_45makersUihflGw9RnMeta?.path ?? "/ar/for-furniture-makers",
    meta: for_45furniture_45makersUihflGw9RnMeta || {},
    alias: for_45furniture_45makersUihflGw9RnMeta?.alias || [],
    redirect: for_45furniture_45makersUihflGw9RnMeta?.redirect,
    component: () => import("/app/pages/for-furniture-makers.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___en",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/en/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___es",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/es/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___ru",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/ru/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___fr",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/fr/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: for_45householdsNX1Wvwxt6cMeta?.name ?? "for-households___ar",
    path: for_45householdsNX1Wvwxt6cMeta?.path ?? "/ar/for-households",
    meta: for_45householdsNX1Wvwxt6cMeta || {},
    alias: for_45householdsNX1Wvwxt6cMeta?.alias || [],
    redirect: for_45householdsNX1Wvwxt6cMeta?.redirect,
    component: () => import("/app/pages/for-households.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___en",
    path: galleryvsTGmCPX6gMeta?.path ?? "/en/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___es",
    path: galleryvsTGmCPX6gMeta?.path ?? "/es/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___ru",
    path: galleryvsTGmCPX6gMeta?.path ?? "/ru/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___fr",
    path: galleryvsTGmCPX6gMeta?.path ?? "/fr/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: galleryvsTGmCPX6gMeta?.name ?? "gallery___ar",
    path: galleryvsTGmCPX6gMeta?.path ?? "/ar/gallery",
    meta: galleryvsTGmCPX6gMeta || {},
    alias: galleryvsTGmCPX6gMeta?.alias || [],
    redirect: galleryvsTGmCPX6gMeta?.redirect,
    component: () => import("/app/pages/gallery.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___en",
    path: getsharedJH75BX2jiuMeta?.path ?? "/en/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___es",
    path: getsharedJH75BX2jiuMeta?.path ?? "/es/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___ru",
    path: getsharedJH75BX2jiuMeta?.path ?? "/ru/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___fr",
    path: getsharedJH75BX2jiuMeta?.path ?? "/fr/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: getsharedJH75BX2jiuMeta?.name ?? "getshared___ar",
    path: getsharedJH75BX2jiuMeta?.path ?? "/ar/getshared",
    meta: getsharedJH75BX2jiuMeta || {},
    alias: getsharedJH75BX2jiuMeta?.alias || [],
    redirect: getsharedJH75BX2jiuMeta?.redirect,
    component: () => import("/app/pages/getshared.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___en",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/en/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___es",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/es/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___ru",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/ru/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___fr",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/fr/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: gitexMoroccocjBvrSaYIUMeta?.name ?? "gitexMorocco___ar",
    path: gitexMoroccocjBvrSaYIUMeta?.path ?? "/ar/gitexMorocco",
    meta: gitexMoroccocjBvrSaYIUMeta || {},
    alias: gitexMoroccocjBvrSaYIUMeta?.alias || [],
    redirect: gitexMoroccocjBvrSaYIUMeta?.redirect,
    component: () => import("/app/pages/gitexMorocco.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___en",
    path: indexamjyqCQoXrMeta?.path ?? "/en/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___es",
    path: indexamjyqCQoXrMeta?.path ?? "/es/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___ru",
    path: indexamjyqCQoXrMeta?.path ?? "/ru/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___fr",
    path: indexamjyqCQoXrMeta?.path ?? "/fr/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexamjyqCQoXrMeta?.name ?? "help-center-about-dream-flat___ar",
    path: indexamjyqCQoXrMeta?.path ?? "/ar/help-center/about-dream-flat",
    meta: indexamjyqCQoXrMeta || {},
    alias: indexamjyqCQoXrMeta?.alias || [],
    redirect: indexamjyqCQoXrMeta?.redirect,
    component: () => import("/app/pages/help-center/about-dream-flat/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___en",
    path: indexeHVpFSjFO4Meta?.path ?? "/en/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___es",
    path: indexeHVpFSjFO4Meta?.path ?? "/es/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___ru",
    path: indexeHVpFSjFO4Meta?.path ?? "/ru/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___fr",
    path: indexeHVpFSjFO4Meta?.path ?? "/fr/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexeHVpFSjFO4Meta?.name ?? "help-center-create-content-and-project-demonstration___ar",
    path: indexeHVpFSjFO4Meta?.path ?? "/ar/help-center/create-content-and-project-demonstration",
    meta: indexeHVpFSjFO4Meta || {},
    alias: indexeHVpFSjFO4Meta?.alias || [],
    redirect: indexeHVpFSjFO4Meta?.redirect,
    component: () => import("/app/pages/help-center/create-content-and-project-demonstration/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___en",
    path: indexMOeWwviCx6Meta?.path ?? "/en/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___es",
    path: indexMOeWwviCx6Meta?.path ?? "/es/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___ru",
    path: indexMOeWwviCx6Meta?.path ?? "/ru/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___fr",
    path: indexMOeWwviCx6Meta?.path ?? "/fr/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexMOeWwviCx6Meta?.name ?? "help-center-creating-project___ar",
    path: indexMOeWwviCx6Meta?.path ?? "/ar/help-center/creating-project",
    meta: indexMOeWwviCx6Meta || {},
    alias: indexMOeWwviCx6Meta?.alias || [],
    redirect: indexMOeWwviCx6Meta?.redirect,
    component: () => import("/app/pages/help-center/creating-project/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___en",
    path: indexNgHTocZnJHMeta?.path ?? "/en/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___es",
    path: indexNgHTocZnJHMeta?.path ?? "/es/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___ru",
    path: indexNgHTocZnJHMeta?.path ?? "/ru/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___fr",
    path: indexNgHTocZnJHMeta?.path ?? "/fr/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexNgHTocZnJHMeta?.name ?? "help-center___ar",
    path: indexNgHTocZnJHMeta?.path ?? "/ar/help-center",
    meta: indexNgHTocZnJHMeta || {},
    alias: indexNgHTocZnJHMeta?.alias || [],
    redirect: indexNgHTocZnJHMeta?.redirect,
    component: () => import("/app/pages/help-center/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___en",
    path: indexN6pT4Un8hYMeta?.path ?? "/en/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___es",
    path: indexN6pT4Un8hYMeta?.path ?? "/es/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ru",
    path: indexN6pT4Un8hYMeta?.path ?? "/ru/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___fr",
    path: indexN6pT4Un8hYMeta?.path ?? "/fr/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index___ar",
    path: indexN6pT4Un8hYMeta?.path ?? "/ar/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___en",
    path: launcherlogin622USJAlVYMeta?.path ?? "/en/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___es",
    path: launcherlogin622USJAlVYMeta?.path ?? "/es/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___ru",
    path: launcherlogin622USJAlVYMeta?.path ?? "/ru/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___fr",
    path: launcherlogin622USJAlVYMeta?.path ?? "/fr/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: launcherlogin622USJAlVYMeta?.name ?? "launcherlogin___ar",
    path: launcherlogin622USJAlVYMeta?.path ?? "/ar/launcherlogin",
    meta: launcherlogin622USJAlVYMeta || {},
    alias: launcherlogin622USJAlVYMeta?.alias || [],
    redirect: launcherlogin622USJAlVYMeta?.redirect,
    component: () => import("/app/pages/launcherlogin.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___en",
    path: license1oPxwXaJfhMeta?.path ?? "/en/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___es",
    path: license1oPxwXaJfhMeta?.path ?? "/es/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___ru",
    path: license1oPxwXaJfhMeta?.path ?? "/ru/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___fr",
    path: license1oPxwXaJfhMeta?.path ?? "/fr/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: license1oPxwXaJfhMeta?.name ?? "license___ar",
    path: license1oPxwXaJfhMeta?.path ?? "/ar/license",
    meta: license1oPxwXaJfhMeta || {},
    alias: license1oPxwXaJfhMeta?.alias || [],
    redirect: license1oPxwXaJfhMeta?.redirect,
    component: () => import("/app/pages/license.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___en",
    path: new8XRwwjkl9dMeta?.path ?? "/en/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___es",
    path: new8XRwwjkl9dMeta?.path ?? "/es/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___ru",
    path: new8XRwwjkl9dMeta?.path ?? "/ru/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___fr",
    path: new8XRwwjkl9dMeta?.path ?? "/fr/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: new8XRwwjkl9dMeta?.name ?? "new___ar",
    path: new8XRwwjkl9dMeta?.path ?? "/ar/new",
    meta: new8XRwwjkl9dMeta || {},
    alias: new8XRwwjkl9dMeta?.alias || [],
    redirect: new8XRwwjkl9dMeta?.redirect,
    component: () => import("/app/pages/new.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___en",
    path: indexTtCmtGQ9NRMeta?.path ?? "/en/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___es",
    path: indexTtCmtGQ9NRMeta?.path ?? "/es/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___ru",
    path: indexTtCmtGQ9NRMeta?.path ?? "/ru/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___fr",
    path: indexTtCmtGQ9NRMeta?.path ?? "/fr/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indexTtCmtGQ9NRMeta?.name ?? "offer___ar",
    path: indexTtCmtGQ9NRMeta?.path ?? "/ar/offer",
    meta: indexTtCmtGQ9NRMeta || {},
    alias: indexTtCmtGQ9NRMeta?.alias || [],
    redirect: indexTtCmtGQ9NRMeta?.redirect,
    component: () => import("/app/pages/offer/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___en",
    path: indextoyI8HEumHMeta?.path ?? "/en/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___es",
    path: indextoyI8HEumHMeta?.path ?? "/es/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___ru",
    path: indextoyI8HEumHMeta?.path ?? "/ru/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___fr",
    path: indextoyI8HEumHMeta?.path ?? "/fr/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: indextoyI8HEumHMeta?.name ?? "offer-pay-status___ar",
    path: indextoyI8HEumHMeta?.path ?? "/ar/offer/pay-status",
    meta: indextoyI8HEumHMeta || {},
    alias: indextoyI8HEumHMeta?.alias || [],
    redirect: indextoyI8HEumHMeta?.redirect,
    component: () => import("/app/pages/offer/pay-status/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___en",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/en/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___es",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/es/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___ru",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/ru/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___fr",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/fr/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93yuH5OfQadmMeta?.name ?? "offer-pay-id___ar",
    path: _91id_93yuH5OfQadmMeta?.path ?? "/ar/offer/pay/:id()",
    meta: _91id_93yuH5OfQadmMeta || {},
    alias: _91id_93yuH5OfQadmMeta?.alias || [],
    redirect: _91id_93yuH5OfQadmMeta?.redirect,
    component: () => import("/app/pages/offer/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___en",
    path: indexpIDCdxr2vsMeta?.path ?? "/en/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___es",
    path: indexpIDCdxr2vsMeta?.path ?? "/es/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___ru",
    path: indexpIDCdxr2vsMeta?.path ?? "/ru/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___fr",
    path: indexpIDCdxr2vsMeta?.path ?? "/fr/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: indexpIDCdxr2vsMeta?.name ?? "offer-plans___ar",
    path: indexpIDCdxr2vsMeta?.path ?? "/ar/offer/plans",
    meta: indexpIDCdxr2vsMeta || {},
    alias: indexpIDCdxr2vsMeta?.alias || [],
    redirect: indexpIDCdxr2vsMeta?.redirect,
    component: () => import("/app/pages/offer/plans/index.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___en",
    path: offer2TwTpaUgg1SMeta?.path ?? "/en/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___es",
    path: offer2TwTpaUgg1SMeta?.path ?? "/es/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___ru",
    path: offer2TwTpaUgg1SMeta?.path ?? "/ru/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___fr",
    path: offer2TwTpaUgg1SMeta?.path ?? "/fr/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: offer2TwTpaUgg1SMeta?.name ?? "offer2___ar",
    path: offer2TwTpaUgg1SMeta?.path ?? "/ar/offer2",
    meta: offer2TwTpaUgg1SMeta || {},
    alias: offer2TwTpaUgg1SMeta?.alias || [],
    redirect: offer2TwTpaUgg1SMeta?.redirect,
    component: () => import("/app/pages/offer2.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___en",
    path: pay_45status0cljlImIloMeta?.path ?? "/en/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___es",
    path: pay_45status0cljlImIloMeta?.path ?? "/es/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___ru",
    path: pay_45status0cljlImIloMeta?.path ?? "/ru/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___fr",
    path: pay_45status0cljlImIloMeta?.path ?? "/fr/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: pay_45status0cljlImIloMeta?.name ?? "pay-status___ar",
    path: pay_45status0cljlImIloMeta?.path ?? "/ar/pay-status",
    meta: pay_45status0cljlImIloMeta || {},
    alias: pay_45status0cljlImIloMeta?.alias || [],
    redirect: pay_45status0cljlImIloMeta?.redirect,
    component: () => import("/app/pages/pay-status.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___en",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/en/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___es",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/es/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___ru",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/ru/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___fr",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/fr/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z8sm1odpbeMeta?.name ?? "pay-id___ar",
    path: _91id_93Z8sm1odpbeMeta?.path ?? "/ar/pay/:id()",
    meta: _91id_93Z8sm1odpbeMeta || {},
    alias: _91id_93Z8sm1odpbeMeta?.alias || [],
    redirect: _91id_93Z8sm1odpbeMeta?.redirect,
    component: () => import("/app/pages/pay/[id].vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___en",
    path: plansTUY73vr0OjMeta?.path ?? "/en/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___es",
    path: plansTUY73vr0OjMeta?.path ?? "/es/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___ru",
    path: plansTUY73vr0OjMeta?.path ?? "/ru/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___fr",
    path: plansTUY73vr0OjMeta?.path ?? "/fr/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: plansTUY73vr0OjMeta?.name ?? "plans___ar",
    path: plansTUY73vr0OjMeta?.path ?? "/ar/plans",
    meta: plansTUY73vr0OjMeta || {},
    alias: plansTUY73vr0OjMeta?.alias || [],
    redirect: plansTUY73vr0OjMeta?.redirect,
    component: () => import("/app/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___en",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/en/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___es",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/es/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___ru",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/ru/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___fr",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/fr/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policyOPxqa3IsSYMeta?.name ?? "privacy-policy___ar",
    path: privacy_45policyOPxqa3IsSYMeta?.path ?? "/ar/privacy-policy",
    meta: privacy_45policyOPxqa3IsSYMeta || {},
    alias: privacy_45policyOPxqa3IsSYMeta?.alias || [],
    redirect: privacy_45policyOPxqa3IsSYMeta?.redirect,
    component: () => import("/app/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___en",
    path: profileupBlgdy3vLMeta?.path ?? "/en/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___es",
    path: profileupBlgdy3vLMeta?.path ?? "/es/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___ru",
    path: profileupBlgdy3vLMeta?.path ?? "/ru/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___fr",
    path: profileupBlgdy3vLMeta?.path ?? "/fr/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: profileupBlgdy3vLMeta?.name ?? "profile___ar",
    path: profileupBlgdy3vLMeta?.path ?? "/ar/profile",
    meta: profileupBlgdy3vLMeta || {},
    alias: profileupBlgdy3vLMeta?.alias || [],
    redirect: profileupBlgdy3vLMeta?.redirect,
    component: () => import("/app/pages/profile.vue").then(m => m.default || m),
    children: [
  {
    name: verify_passwordgLQvYSM7fBMeta?.name ?? "profile-verify_password",
    path: verify_passwordgLQvYSM7fBMeta?.path ?? "verify_password",
    meta: verify_passwordgLQvYSM7fBMeta || {},
    alias: verify_passwordgLQvYSM7fBMeta?.alias || [],
    redirect: verify_passwordgLQvYSM7fBMeta?.redirect,
    component: () => import("/app/pages/profile/verify_password.vue").then(m => m.default || m)
  },
  {
    name: verify_signupoFYdrbpbWiMeta?.name ?? "profile-verify_signup",
    path: verify_signupoFYdrbpbWiMeta?.path ?? "verify_signup",
    meta: verify_signupoFYdrbpbWiMeta || {},
    alias: verify_signupoFYdrbpbWiMeta?.alias || [],
    redirect: verify_signupoFYdrbpbWiMeta?.redirect,
    component: () => import("/app/pages/profile/verify_signup.vue").then(m => m.default || m)
  }
]
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___en",
    path: real_45estateRBx1btoobbMeta?.path ?? "/en/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___es",
    path: real_45estateRBx1btoobbMeta?.path ?? "/es/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___ru",
    path: real_45estateRBx1btoobbMeta?.path ?? "/ru/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___fr",
    path: real_45estateRBx1btoobbMeta?.path ?? "/fr/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: real_45estateRBx1btoobbMeta?.name ?? "real-estate___ar",
    path: real_45estateRBx1btoobbMeta?.path ?? "/ar/real-estate",
    meta: real_45estateRBx1btoobbMeta || {},
    alias: real_45estateRBx1btoobbMeta?.alias || [],
    redirect: real_45estateRBx1btoobbMeta?.redirect,
    component: () => import("/app/pages/real-estate.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___en",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/en/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___es",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/es/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___ru",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/ru/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___fr",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/fr/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: terms_45of_45useA3Owpnmw23Meta?.name ?? "terms-of-use___ar",
    path: terms_45of_45useA3Owpnmw23Meta?.path ?? "/ar/terms-of-use",
    meta: terms_45of_45useA3Owpnmw23Meta || {},
    alias: terms_45of_45useA3Owpnmw23Meta?.alias || [],
    redirect: terms_45of_45useA3Owpnmw23Meta?.redirect,
    component: () => import("/app/pages/terms-of-use.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___en",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/en/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___es",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/es/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___ru",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/ru/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___fr",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/fr/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_designerOlLS7dGGyrMeta?.name ?? "thank_you_designer___ar",
    path: thank_you_designerOlLS7dGGyrMeta?.path ?? "/ar/thank_you_designer",
    meta: thank_you_designerOlLS7dGGyrMeta || {},
    alias: thank_you_designerOlLS7dGGyrMeta?.alias || [],
    redirect: thank_you_designerOlLS7dGGyrMeta?.redirect,
    component: () => import("/app/pages/thank_you_designer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___en",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/en/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___es",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/es/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___ru",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/ru/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___fr",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/fr/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: thank_you_developerr71Kq0pOduMeta?.name ?? "thank_you_developer___ar",
    path: thank_you_developerr71Kq0pOduMeta?.path ?? "/ar/thank_you_developer",
    meta: thank_you_developerr71Kq0pOduMeta || {},
    alias: thank_you_developerr71Kq0pOduMeta?.alias || [],
    redirect: thank_you_developerr71Kq0pOduMeta?.redirect,
    component: () => import("/app/pages/thank_you_developer.vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___en",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/en/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___es",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/es/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___ru",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/ru/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___fr",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/fr/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93vBwXVvXpAaMeta?.name ?? "verify-id___ar",
    path: _91id_93vBwXVvXpAaMeta?.path ?? "/ar/verify/:id()",
    meta: _91id_93vBwXVvXpAaMeta || {},
    alias: _91id_93vBwXVvXpAaMeta?.alias || [],
    redirect: _91id_93vBwXVvXpAaMeta?.redirect,
    component: () => import("/app/pages/verify/[id].vue").then(m => m.default || m)
  }
]